<template>
  <div>
    <div v-if="chats && chats.length" class="position-table">
      <md-table
        v-model="chats"
        :md-sort-fn="customSort"
        :md-sort.sync="customSortBy"
        :md-sort-order.sync="customSortOrder"
      >
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="ID" md-sort-by="id" class="custom-field">
            <span>{{ item.id }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="ID заказа"
            md-sort-by="name"
            class="custom-field"
          >
            <span>{{ item.order ? item.order.id : "" }}</span>
          </md-table-cell>

          <md-table-cell
            md-label="Статус"
            md-sort-by="is_active"
            class="custom-field"
          >
            <span>{{ item.status === 'open' ? "Открыт" : "Закрыт" }}</span>
          </md-table-cell>

          <md-table-cell>
            <div class="edit-and-delete-block">
              <md-button
                class="md-just-icon md-simple md-primary"
                :to="`/chats/${item.id}`"
              >
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">Редактировать</md-tooltip>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <div v-if="chats.last_page > 1" class="pagination">
        <md-button
          class="md-primary"
          :disabled="chats.current_page === 1"
          @click="$emit('prev')"
        >
          <md-icon>navigate_before</md-icon>
        </md-button>
        <div class="pages">
          <span>{{ chats.current_page }}</span>
          <span>{{ chats.last_page }}</span>
        </div>
        <md-button
          class="md-primary"
          :disabled="chats.current_page === chats.last_page"
          @click="$emit('next')"
        >
          <md-icon>navigate_next</md-icon>
        </md-button>
      </div>
    </div>
    <div v-else class="my-empty empty">Пусто</div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {
      customSortBy: "id",
      customSortOrder: "asc",
    };
  },

  computed: {
    ...mapState({
      chats: (state) => state.chats.chats,
    }),
  },

  methods: {
    ...mapActions(["getCounters"]),

    customSort(value) {
      this.$emit("sort", {
        sortBy: this.customSortBy,
        sortOrder: this.customSortOrder,
      });
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;

  .pages {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
}

::v-deep {
  .md-table-sortabl0e-icon {
    left: unset !important;
    right: 8px !important;
  }

  .md-table-row {
    cursor: pointer;
  }
}
</style>
